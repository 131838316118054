import { PageId } from '../../constants';
import { ComponentStructure } from '../../migration/domain';
import { getNewFormPageData } from '../form-page-migration/settings-data-transpiler';
import { getNewFormPageStyles } from '../form-page-migration/styles-transpiler';
import { BasePageMigrator } from './base-page-migrator';
import { isBookingFormInstalled } from '../editor-sdk-actions';

export class FormPageMigrator extends BasePageMigrator {
  constructor(protected editorSDK, protected appToken, protected instance) {
    super(editorSDK, appToken, instance);
  }

  public async shouldMigrate(): Promise<boolean> {
    const bookingFormInstalled = await isBookingFormInstalled(this.editorSDK);

    return !bookingFormInstalled;
  }

  public async execute() {
    await this.addBookingsPage(PageId.BOOKINGS_FORM_PAGE);

    const siteStructure = await this.getSiteStructure();
    const checkoutStructure: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.bookingCheckoutWidgetId,
    );
    const formStructure: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.widgetId,
    );

    if (!checkoutStructure) {
      throw new Error('Bookings Checkout Page not exist - errorCode: 82aca05b-4faa-4b99-9d11-d9e95ea84c2e');
    }

    if (checkoutStructure.style?.style?.properties) {
      console.log('Old Calendar Page Properties:');
      console.log(checkoutStructure);
      console.table(checkoutStructure.style.style.properties);
      console.log('New Calendar Page structure: (Before Change)');
      console.log(formStructure);
      console.table(formStructure.style?.style?.properties);
      const newFormPageData = getNewFormPageData(
        checkoutStructure.style.style.properties,
      );
      console.log('Layouts to write:');
      console.table(newFormPageData);
      if (newFormPageData) {
        await this.updateComponentSettings(formStructure.id, newFormPageData);
      }
      const newFormPageStyles = getNewFormPageStyles(
        checkoutStructure.style.style.properties,
      );
      console.log('Styles to write:');
      console.table(newFormPageStyles);

      if (newFormPageStyles) {
        await this.updateComponentStyles(formStructure.id, newFormPageStyles);
      }
    } else {
      console.log('Old calendar page is plain, no styles to migrate.');
    }

    await this.createPageState(PageId.BOOKINGS_FORM_PAGE, 'bookingForm');
  }

  protected get widgetId() {
    return '985e6fc8-ce3f-4cf8-9b85-714c73f48695';
  }

  public get widgetName() {
    return 'FormPageMigrator';
  }
}
